import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Dialog, Typography, Stack, Link, useMediaQuery, Slide, IconButton, Box } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import loadingGif from 'assets/images/icons/kOnzy.gif';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReactPdf from '../../PunteoPagos/components/ReactPdf';

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
// slide animation

// ===============================|| UI DIALOG - FULL SCREEN ||=============================== //

export default function ComprobanteDialog({ open, data, handleCloseDialog, loading }) {
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    };

    const [rows, setRows] = useState({});
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const componentRef = useRef(null);

    const [isView, setIsView] = React.useState(false);
    const [pedidosData, setPedidosData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [image, setImage] = React.useState('');
    const [estatus, setEstatus] = React.useState();

    let statusText = '';
    let statusColor = '';

    if (data.sysop_esid == 1) {
        statusText = 'Programado';
        statusColor = '#277BC0';
    } else if (data.sysop_esid == 4) {
        statusText = 'Entregado';
        statusColor = '#3CCF4E';
    } else if (data.sysop_esid == 5) {
        statusText = 'Cancelado';
        statusColor = '#EB1D36';
    } else {
        statusText = 'Desconocido';
        statusColor = '#EFEFEF';
    }

    React.useEffect(() => {
        console.log(data);
        initStateFunction(data);
    }, [data]);

    const initStateFunction = (pago) => {
        /*  const avatarProfile = pago.comp_url 
        ? `https://sp-proxy.segpak.workers.dev/corsproxy/?apiurl=http://uploads.lamensajeria.mx/fichas/${pago.comp_url}` 
        : 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fnoimage.png?alt=media&token=6a90e633-1a07-40f6-9db3-3db6d792152f'; */

        const avatarProfile = pago.comp_url
            ? `${pago.comp_url}`
            : 'https://firebasestorage.googleapis.com/v0/b/segpakapp.appspot.com/o/hawk%2Fcompany%2Fnoimage.png?alt=media&token=6a90e633-1a07-40f6-9db3-3db6d792152f';

        setImage(avatarProfile);
        setEstatus(pago.c);
    };

    const closeBarcodeDialog = (val) => {
        console.log(val);
        setOpenBarcode(false);
        //{isLoading && <LinearProgress sx={{my:1}}/>}
    };

    return (
        <Dialog
            open={open}
            scroll="body"
            sx={{
                '&>div:nth-of-type(3)': {
                    justifyContent: 'flex-end',
                    '&>div': {
                        m: 0,
                        p: 0,
                        borderRadius: '0px',
                        minWidth: matchDownMd ? 0.95 : 0.6,
                        minHeight: '100%'
                    }
                }
            }}
        >
            {open && (
                <>
                    <Grid sx={{ ml: 2 }}>
                        <Stack direction="row" justifyContent="space-between" sx={{ mx: 0, my: 2 }}>
                            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close" size="large">
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid>
                        <Stack direction="row" justifyContent="space-between" sx={{ mx: 0, my: 2 }}>
                            <Box sx={{ height: '80vh', width: matchDownMd ? '45%' : '65%' }}>
                                {data.imgType == 2 ? (
                                    <Grid container justifyContent="center" sx={{ textAlign: 'center' }}>
                                        <ReactPdf fileName={data.comp_url} />
                                    </Grid>
                                ) : (
                                    <Grid container justifyContent="center" sx={{ textAlign: 'center' }}>
                                        <InnerImageZoom
                                            src={image}
                                            alt="Pago"
                                            width={matchDownMd ? '95%' : '70%'}
                                            zoomScale={matchDownMd ? 0.5 : 1}
                                        />
                                    </Grid>
                                )}
                            </Box>

                            <Box sx={{ height: '80vh', width: matchDownMd ? '50%' : '30%' }}>
                                <Stack>
                                    <Stack direction="row" justifyContent="space-between" sx={{ mx: 0, my: 0, height: 50 }}>
                                        <Typography variant="h3" sx={{ mt: 1 }}></Typography>
                                        {data.c == 11 && <InfoIcon sx={{ fontSize: 45, color: '#277BC0', mr: 2 }} />}
                                    </Stack>

                                    <Typography variant="h3" sx={{ mt: 1 }}>
                                        {data.sysId}
                                    </Typography>
                                    <Typography variant="h6" align="left" sx={{ fontSize: 14, fontWeight: 500, color: statusColor, mb: 1 }}>
                                        {statusText}
                                    </Typography>
                                    <Typography variant="h6" sx={{ mt: 0 }}>
                                        {data.nombre}
                                    </Typography>
                                    <Typography variant="caption" sx={{ mt: 0 }}>
                                        {data.compania}
                                    </Typography>
                                    <Typography variant="caption" sx={{ mt: 0 }}>
                                        {data.correo}
                                    </Typography>
                                    <Typography variant="caption" sx={{ mt: 0 }}>
                                        {data.telefono}
                                    </Typography>

                                    <Typography variant="h1" sx={{ mt: 2, fontSize: 30 }}>
                                        ${data.precio_venta}.00
                                    </Typography>
                                    <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                                        <CheckCircleIcon sx={{ fontSize: 16, color: data.c == 10 ? '#3CCF4E' : '#EFEFEF' }} />
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: matchDownMd ? 9 : 12,
                                                fontWeight: 500,
                                                color: data.c == 10 ? '#3CCF4E' : '#EFEFEF',
                                                ml: 0.5
                                            }}
                                        >
                                            Confirmado
                                        </Typography>
                                    </Stack>

                                    <Button
                                        variant="contained"
                                        onClick={handleCloseDialog}
                                        size="large"
                                        sx={{ mt: 4, bgcolor: '#277BC0', mx: matchDownMd ? 0 : 3, mr: matchDownMd ? 3 : 4 }}
                                    >
                                        Salir
                                    </Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                </>
            )}
        </Dialog>
    );
}
