// third-party
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

// assets
import {
    IconDashboard,
    IconDeviceAnalytics,
    IconFileExport,
    IconListDetails,
    IconSquarePlus,
    IconPrinter,
    IconListCheck,
    IconSearch,
    IconFileDollar,
    IconBarcode,
    IconRoad,
    IconPackgeImport,
    IconMap,
    IconMap2,
    IconFileOff
} from '@tabler/icons';
import DatabaseIcon from '../ui-component/icons/DatabaseIcon';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconFileExport,
    IconListDetails,
    IconSquarePlus,
    AddCircleOutlineIcon,
    IconPrinter,
    IconListCheck,
    IconSearch,
    IconFileDollar,
    IconBarcode,
    IconRoad,
    IconPackgeImport,
    IconMap,
    IconMap2,
    IconFileOff
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'pedidos',
            title: <Typography variant="body">Pedidos adm</Typography>,
            type: 'collapse',
            icon: icons.IconListDetails,
            children: [
                {
                    id: 'printmod',
                    title: <Typography variant="body">Impresión</Typography>,
                    type: 'item',
                    url: '/pedidosprint',
                    icon: icons.IconPrinter,
                    breadcrumbs: false
                },
                /*  {
                    id: 'migrate',
                    title: <Typography variant='body'>Migrate</Typography>,
                    type: 'item',
                    url: '/migrate',
                    icon: icons.IconSquarePlus,
                    breadcrumbs: false
                }, */
                {
                    id: 'punteopagos',
                    title: <Typography variant="body">Confirmación de pagos</Typography>,
                    type: 'item',
                    url: '/punteopagos',
                    icon: icons.IconListCheck,
                    breadcrumbs: false
                },
                {
                    id: 'pedidossearch',
                    title: <Typography variant="body">Buscar</Typography>,
                    type: 'item',
                    url: '/pedidossearch',
                    icon: icons.IconSearch,
                    breadcrumbs: false
                },
                {
                    id: 'cobrosmain',
                    title: <Typography variant="body">Cobros</Typography>,
                    type: 'item',
                    icon: icons.IconFileDollar,
                    url: '/cobros/main',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'operaciones',
            title: <Typography variant="body">Operaciones</Typography>,
            type: 'collapse',
            icon: icons.IconBarcode,
            children: [
                {
                    id: 'punteo',
                    title: <Typography variant="body">Punteo</Typography>,
                    type: 'collapse',
                    icon: icons.IconBarcode,
                    children: [
                        {
                            id: 'punteorec',
                            title: <Typography variant="body">Punteo recolecciones</Typography>,
                            type: 'item',
                            url: '/opspvrec',
                            icon: icons.IconBarcode,
                            breadcrumbs: false
                        },
                        {
                            id: 'punteoent',
                            title: <Typography variant="body">Punteo entregas</Typography>,
                            type: 'item',
                            url: '/opspvent',
                            icon: icons.IconBarcode,
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'recibo',
                    title: <Typography variant="body">Recibo</Typography>,
                    type: 'collapse',
                    icon: icons.IconPackgeImport,
                    children: [
                        {
                            id: 'routes',
                            title: <Typography variant="body">Recorridos</Typography>,
                            type: 'item',
                            url: '/recorridos',
                            icon: icons.IconRoad,
                            breadcrumbs: false
                        },
                        {
                            id: 'reporteDespacho',
                            title: <Typography variant="body">Reporte despacho</Typography>,
                            type: 'item',
                            url: '/repdespacho',
                            icon: icons.IconListDetails,
                            breadcrumbs: false
                        }

                        /*  {
                            id: 'routes-map',
                            title: <Typography variant='body'>Mapa recorridos</Typography>,
                            type: 'item',
                            url: '/routesmap',
                            icon: icons.IconMap2,
                            breadcrumbs: false
                        } */
                    ]
                },
                {
                    id: 'reportes',
                    title: <Typography variant="body">Reportes</Typography>,
                    type: 'collapse',
                    icon: icons.IconDashboard,
                    children: [
                        {
                            id: 'routes',
                            title: <Typography variant="caption">Guías sin movimiento</Typography>,
                            type: 'item',
                            url: '/repopsguias',
                            icon: icons.IconFileOff,
                            breadcrumbs: false
                        },
                        {
                            id: 'repfacturacion',
                            title: <Typography variant="caption">Facturación</Typography>,
                            type: 'item',
                            url: '/repfacturacion',
                            icon: icons.IconListDetails,
                            breadcrumbs: false
                        },
                        {
                            id: 'repconsolidado',
                            title: <Typography variant="caption">Consolidado</Typography>,
                            type: 'item',
                            url: '/repconsolidado',
                            icon: icons.IconListDetails,
                            breadcrumbs: false
                        }
                    ]
                }
            ]
        },
        {
            id: 'monitoreo',
            title: <Typography variant="body">Monitoreo</Typography>,
            type: 'item',
            url: '/monitoreo',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default admin;
